import React, { useState } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  Button, 
  Card, 
  CardContent,
  CardActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { PopupModal } from 'react-calendly';
import { AccessTime, CheckCircleOutline, EditOutlined, MenuBookOutlined, SchoolOutlined } from '@mui/icons-material';

const serviceOptions = [
  {
    title: '30 Minute Consultation',
    description: 'Book a complimentary 30-minute consultation with Olivia to get started. During this session, we\'ll understand your student\'s goals, identify any challenges, and create a plan that works for you and your student.',
    icon: AccessTime,
    url: 'https://calendly.com/olivia-v-p-lewis/initial-consultation',
    buttonText: 'Book Consultation',
    features: [
      'Outline your specific needs',
      'Make an actionable plan',
      'Set a meeting schedule'
    ]
  },
  {
    title: 'Language Arts Tutoring',
    description: 'Personalized one-on-one sessions dedicated to developing strong reading, writing, and analytical skills. From literary analysis to creative writing, students receive targeted instruction to help them excel in their Language Arts courses while building confidence in their writing abilities.',
    icon: MenuBookOutlined,
    url: 'https://calendly.com/olivia-v-p-lewis/language-arts-tutoring-session',
    features: [
      'Targeted instruction in writing mechanics and style',
      'Literary analysis and critical reading strategies',
      'Ongoing feedback and skill development tracking'
    ]
  },
  {
    title: 'Essay Review',
    description: 'In-depth review and feedback on your academic or college application essays. Receive detailed guidance on strengthening your argument development, improving clarity, and enhancing your writing style. Includes both written feedback and a live discussion of revision strategies.',
    icon: EditOutlined,
    url: 'https://calendly.com/olivia-v-p-lewis/essay-editing',
    features: [
      'Detailed written feedback on content, structure, and style',
      'Optional, live follow-up reviews'
    ]
  },
  {
    title: 'College Counseling',
    description: 'Comprehensive guidance through the college application process focused on helping students find their ideal school. From building a college list to writing essays and selecting a school, students receive strategic advice to strengthen their applications and showcase their unique academic stories.',
    icon: SchoolOutlined,
    url: 'https://calendly.com/olivia-v-p-lewis/college-counseling-session',
    features: [
      'Personalized college list development and application strategy',
      'Strategic review of academic profile and extracurriculars',
      'Detailed instruction on essays and application materials'
    ]
  }
];

const Services = () => {
  const [openCalendarUrl, setOpenCalendarUrl] = useState('');

  return (
    <Box sx={{ 
      bgcolor: 'var(--sage-light)',
      minHeight: '100vh',
      pt: { xs: 8, md: 12 },
      pb: { xs: 6, md: 8 },
    }}>
      <Container maxWidth="2xl" sx={{ px: { xs: 2, sm: 4, md: 6, lg: 8 } }}>
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography 
            variant="h2" 
            component="h1" 
            gutterBottom
            sx={{
              color: 'var(--text-dark)',
              fontWeight: 600,
              fontSize: { xs: '2rem', md: '3rem' },
              mb: 3
            }}
          >
            Services Offered
          </Typography>
          <Typography 
            variant="h6" 
            sx={{ 
              maxWidth: 800, 
              mx: 'auto',
              color: 'var(--text-light)',
              fontWeight: 400,
            }}
          >
            Begin by booking a complimentary consultation to map out a personalized education or college counseling plan. Preview the services offered to LEC students after the initial consultation, including Language Arts Tutoring, College Counseling, and Essay Reviews.
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: '1fr',
              md: 'repeat(2, 1fr)',
              lg: 'repeat(4, 1fr)'
            },
            gap: { xs: 3, md: 4 },
            maxWidth: '100%',
            mx: 'auto'
          }}
        >
          {serviceOptions.map((option, index) => {
            const Icon = option.icon;
            return (
              <Card
                key={index}
                elevation={0}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  bgcolor: 'var(--warm-white)',
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'var(--sage-mid)',
                  borderOpacity: 0.2,
                  transition: 'all 0.2s',
                  '&:hover': {
                    borderColor: 'var(--accent-green)',
                    transform: 'translateY(-2px)',
                  }
                }}
              >
                <CardContent sx={{ flexGrow: 1, p: { xs: 3, md: 4 } }}>
                  <Box sx={{ textAlign: 'center', mb: 3 }}>
                    <Icon sx={{ 
                      fontSize: 48, 
                      color: 'var(--accent-green)', 
                      mb: 2 
                    }} />
                    <Typography 
                      variant="h5" 
                      component="h2" 
                      gutterBottom
                      sx={{
                        color: 'var(--text-dark)',
                        fontWeight: 600
                      }}
                    >
                      {option.title}
                    </Typography>
                  </Box>

                  <Typography 
                    variant="body1"
                    sx={{
                      color: 'var(--text-light)',
                      mb: 3
                    }}
                  >
                    {option.description}
                  </Typography>

                  <List>
                    {option.features.map((feature, idx) => (
                      <ListItem key={idx} sx={{ px: 0 }}>
                        <ListItemIcon sx={{ minWidth: 36 }}>
                          <CheckCircleOutline sx={{ color: 'var(--accent-green)' }} />
                        </ListItemIcon>
                        <ListItemText 
                          primary={feature} 
                          primaryTypographyProps={{
                            sx: { color: 'var(--text-light)' }
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>

                {option.buttonText &&
                  <CardActions sx={{ p: 4, pt: 0 }}>
                    <Button
                      fullWidth
                      variant="contained"
                      size="large"
                      onClick={() => setOpenCalendarUrl(option.url)}
                      sx={{
                        color: 'var(--warm-white)',
                        bgcolor: 'var(--forest-green)',
                        py: 1.5,
                        textTransform: 'none',
                        fontSize: '1.1rem',
                        transition: 'all 0.2s ease-in-out',
                        '&:hover': {
                          bgcolor: 'var(--accent-green)',
                          transform: 'translateY(-2px)',
                          boxShadow: 2
                        }
                      }}
                    >
                      {option.buttonText}
                    </Button>
                  </CardActions>
                }
              </Card>
            );
          })}
        </Box>

        <PopupModal
          url={openCalendarUrl}
          onModalClose={() => setOpenCalendarUrl('')}
          open={Boolean(openCalendarUrl)}
          rootElement={document.getElementById("root")}
        />
      </Container>
    </Box>
  );
};

export default Services;