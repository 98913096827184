import { createTheme } from '@mui/material/styles';

import { colors } from './colors';


const theme = createTheme({
  palette: {
    primary: {
      main: colors.midnightBlue, // Midnight Blue
      light: colors.blueGray, // Blue Gray (lighter shade)
      dark: colors.darkBlue, // Dark Blue (darker shade)
      contrastText: colors.mistyBlue, // Misty Blue for text on primary
    },
    secondary: {
      main: colors.blueGray, // Blue Gray
      light: colors.mistyBlue, // Misty Blue (lighter shade)
      dark: '#4A5F6E', // Darker shade of Blue Gray
      contrastText: colors.midnightBlue, // Midnight Blue for text on secondary
    },
    tertiary: {
      main: colors.mistyBlue, // Misty Blue
      light: '#E0E7ED', // Lighter Misty Blue
      dark: '#A3B8CC', // Darker Misty Blue
      contrastText: colors.midnightBlue, // Midnight Blue for text on tertiary
    },
    background: {
      default: colors.mistyBlue, // Misty Blue for background
      paper: '#FFFFFF', // White for cards/paper elements
    },
    text: {
      primary: colors.midnightBlue, // Midnight Blue for primary text
      secondary: colors.blueGray, // Blue Gray for secondary text
    },
  },
  typography: {
    fontFamily: '"Cormorant Garamond", serif', // Customize fonts
  },
});

export default theme;