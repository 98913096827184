import React from 'react';

import { Box } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Services from './Services';
import Header from './Header';
import Home from './Home';
import Footer from './Footer';

function App() {
  return (
    <Router basename='/'>
      {/* Flex container to push footer to the bottom */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh', // Ensure the container takes at least the full viewport height
        }}
      >
        <Header />
        {/* Main content area that grows to fill available space */}
        <Box sx={{ flexGrow: 1,  pt: 8 }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
          </Routes>
        </Box>
        <Footer />
      </Box>
    </Router>
  );
}

export default App;