import React, { useState } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  IconButton, 
  Drawer, 
  Box, 
  List, 
  ListItem, 
  ListItemText, 
  useMediaQuery, 
  useTheme, 
  styled 
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

const StyledButton = styled(Button)({
  color: 'var(--warm-white)',
  textTransform: 'none',
  fontSize: '1rem',
  padding: '8px 16px',
  marginLeft: '16px',
  borderRadius: '4px',
  transition: 'all 0.2s',
  '&:hover': {
    backgroundColor: 'var(--accent-green)',
    transform: 'translateY(-1px)',
  },
});

const Header = () => {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navLinks = [
    { text: 'Home', path: '/' },
    { text: 'Services', path: '/services' },
  ];

  const drawer = (
    <Box 
      onClick={handleDrawerToggle} 
      sx={{ 
        textAlign: 'left',
        bgcolor: 'var(--warm-white)',
        height: '100%'
      }}
    >
      <Typography 
        variant="h6" 
        sx={{ 
          m: 2, 
          color: 'var(--text-dark)',
          fontWeight: 500
        }}
      >
        Lewis Educational Consulting
      </Typography>
      <List>
        {navLinks.map((link) => (
          <ListItem 
            button 
            key={link.text} 
            component={Link} 
            to={link.path}
            sx={{
              color: 'var(--text-light)',
              '&:hover': {
                bgcolor: 'var(--sage-light)'
              }
            }}
          >
            <ListItemText 
              primary={link.text}
              primaryTypographyProps={{
                sx: { fontWeight: 500 }
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar 
      position="fixed" 
      elevation={0}
      sx={{
        bgcolor: 'var(--forest-green)',
        borderBottom: '1px solid var(--deep-green)',
      }}
    >
      <Toolbar sx={{ height: 70 }}>
        {isMobile && (
          <IconButton
            bgcolor='var(--warm-white)'
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2,
              color: 'var(--warm-white)',
            }}
          >
            <MenuIcon />
          </IconButton>
        )}

        <Typography 
          variant="h6" 
          component={Link} 
          to="/"
          sx={{ 
            flexGrow: 1,
            color: 'var(--warm-white)',
            textDecoration: 'none',
            fontWeight: 500,
            fontSize: { xs: '1.1rem', md: '1.25rem' },
            '&:hover': {
              color: 'var(--warm-white)',
            }
          }}
        >
          Lewis Educational Consulting
        </Typography>

        {!isMobile && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {navLinks.map((link) => (
              <StyledButton 
                key={link.text} 
                component={Link} 
                to={link.path}
              >
                {link.text}
              </StyledButton>
            ))}
          </Box>
        )}
      </Toolbar>

      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          '& .MuiDrawer-paper': { 
            boxSizing: 'border-box', 
            width: 240,
            bgcolor: 'var(--warm-white)',
          },
        }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
};

export default Header;