import React from 'react';
import { Box, Container, Typography, Link, Grid } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
  return (
    <Box 
      component="footer" 
      sx={{
        bgcolor: 'var(--forest-green)',
        color: 'var(--warm-white)',
        py: 3,
        borderTop: '1px solid var(--deep-green)',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
          <Grid item xs={12} md="auto">
            <Typography 
              variant="body2" 
              sx={{
                textAlign: { xs: 'center', md: 'left' },
                mb: { xs: 1, md: 0 }
              }}
            >
              © {new Date().getFullYear()} Lewis Educational Consulting
            </Typography>
          </Grid>
          
          <Grid item xs={12} md="auto">
            <Box 
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 3,
                '& a': {
                  color: 'var(--warm-white)',
                  textDecoration: 'none',
                  fontSize: '0.875rem',
                  transition: 'all 0.2s',
                  opacity: 0.9,
                  '&:hover': {
                    opacity: 1,
                    color: 'var(--sage-light)'
                  }
                }
              }}
            >
              <Link component={RouterLink} to="/">Home</Link>
              <Link component={RouterLink} to="/services">Services</Link>
              <Link href="mailto:olivia@lewiseducationalconsulting.com">Contact</Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;