import React from 'react';
import { 
  Container, 
  Typography, 
  Button, 
  Box, 
  Avatar,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import { 
  ArrowForward,
  MenuBook,
  Edit,
  School,
} from '@mui/icons-material';

const serviceDetails = [
  {
    title: 'Language Arts Tutoring',
    icon: MenuBook,
    description: 'Develop fundamental skills in reading, writing, and analysis through 1-on-1 tutoring. Build confidence in your English and Social Studies classes or collaborate on personal writing projects.',
    highlights: [
      'Analytical writing skills',
      'Literary Analysis',
      'Close reading strategies'
    ]
  },
  {
    title: 'Essay Review',
    icon: Edit,
    description: 'Craft compelling essays through an iterative process for both in-class assignments and college admissions essays',
    highlights: [
      'Structured feedback',
      'Argument and thesis development',
      'Writing style refinement'
    ]
  },
  {
    title: 'College Counseling',
    icon: School,
    description: 'Student driven college planning to guide you from building your college list to selecting a school. Whatever your needs or goals are for college, we will address them as a team.',
    highlights: [
      'Intentional college selection',
      'Application strategy',
      'Essay development'
    ]
  }
];

const Home = () => {
  return (
    <Box sx={{ 
      bgcolor: 'var(--sage-light)',
      minHeight: '100vh',
    }}>
      {/* Hero Section */}
      <Box
        sx={{
          pt: { xs: 8, md: 12 },
          pb: { xs: 6, md: 8 },
          background: 'linear-gradient(180deg, var(--sage-light) 0%, var(--warm-white) 100%)',
        }}
      >
        <Container maxWidth="lg">
          <Box 
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              maxWidth: '800px',
              mx: 'auto'
            }}
          >
            {/* Title */}
            <Typography 
              variant="h1" 
              sx={{
                fontSize: { xs: '2.5rem', md: '3.75rem' },
                fontWeight: 600,
                color: 'var(--text-dark)',
                mb: 4,
              }}
            >
              Lewis Educational Consulting
            </Typography>

            {/* Subtitle */}
            <Typography 
              variant="h5" 
              sx={{ 
                color: 'var(--text-light)',
                mb: 4,
                lineHeight: 1.6,
                maxWidth: '600px'
              }}
            >
              Developing strong writers, successful college applicants, and critical thinkers through a personalized, holistic approach to language arts tutoring and college counseling.
            </Typography>

            {/* Button */}
            <Button 
              variant="contained" 
              href="/services" 
              endIcon={<ArrowForward />}
              sx={{
                bgcolor: 'var(--forest-green)',
                color: 'var(--warm-white)',
                py: 1.5,
                px: 4,
                borderRadius: 1,
                textTransform: 'none',
                fontSize: '1.1rem',
                transition: 'all 0.2s',
                '&:hover': {
                  bgcolor: 'var(--accent-green)',
                  transform: 'translateY(-2px)',
                  boxShadow: 2
                }
              }}
            >
              Schedule a Consultation
            </Button>
          </Box>
        </Container>
      </Box>

      {/* Services Section */}
      <Container maxWidth="lg" sx={{ py: { xs: 6, md: 10 } }}>
        <Grid container spacing={4}>
          {serviceDetails.map((service, index) => {
            const Icon = service.icon;
            return (
              <Grid item xs={12} md={4} key={index}>
                <Card
                  elevation={0}
                  sx={{
                    height: '100%',
                    bgcolor: 'var(--warm-white)',
                    borderRadius: 2,
                    border: '1px solid',
                    borderColor: 'var(--sage-mid)',
                    borderOpacity: 0.2,
                    transition: 'all 0.2s',
                    '&:hover': {
                      borderColor: 'var(--accent-green)',
                    }
                  }}
                >
                  <CardContent sx={{ p: 4 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                      <Icon 
                        sx={{ 
                          fontSize: 32,
                          color: 'var(--accent-green)',
                          mr: 2
                        }} 
                      />
                      <Typography 
                        variant="h5"
                        sx={{
                          fontWeight: 600,
                          color: 'var(--text-dark)',
                        }}
                      >
                        {service.title}
                      </Typography>
                    </Box>
                    <Typography sx={{ color: 'var(--text-light)', mb: 3 }}>
                      {service.description}
                    </Typography>
                    <Box sx={{ pl: 1 }}>
                      {service.highlights.map((highlight, idx) => (
                        <Box key={idx} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <Typography 
                            sx={{ 
                              color: 'var(--text-light)',
                              fontSize: '0.95rem',
                              '&::before': {
                                content: '"•"',
                                marginRight: '8px',
                                color: 'var(--accent-green)'
                              }
                            }}
                          >
                            {highlight}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>

      {/* About Section */}
      <Box sx={{ 
        py: { xs: 6, md: 10 },
        background: 'linear-gradient(180deg, var(--warm-white) 0%, var(--sage-light) 100%)',
      }}>
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={4}>
              <Avatar
                alt="Olivia Lewis"
                src={'/ovpl_headshot.jpeg'}
                sx={{
                  width: '100%',
                  height: 'auto',
                  aspectRatio: '1',
                  borderRadius: 15,
                }}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography 
                variant="h4" 
                gutterBottom 
                sx={{
                  fontWeight: 600,
                  color: 'var(--text-dark)',
                  mb: 3
                }}
              >
                About Olivia
              </Typography>
              <Typography 
                sx={{ 
                  mb: 3,
                  fontSize: '1.1rem',
                  lineHeight: 1.7,
                  color: 'var(--text-light)'
                }}
              >
                Lewis Educational Consulting was born out of founder Olivia Lewis's passions for both working with students and writing. During her undergraduate studies at the University of Southern California, Olivia began helping her peers develop and revise their admissions essays for graduate school and working part time as a writing tutor. Since 2017, Olivia has helped students develop as readers and writers in all stages of their academic journeys - from elementary school through college. Olivia graduated from USC with both a Bachelor's and a Master's degree in economics, which informs her analytical approach to effective writing and college counseling. Olivia's students have applied to and been accepted by schools such as University of Michigan, CU Boulder, Yale, University of Southern California, UC Berkeley, and many others.
              </Typography>
              <Typography 
                sx={{ 
                  fontSize: '1.1rem',
                  lineHeight: 1.7,
                  color: 'var(--text-light)'
                }}
              >
                Olivia's approach focuses on empowering students to find their voice and develop the fundamental skills they need for long-term academic success. By emphasizing strong writing abilities and critical thinking, she helps students not just with college applications, but with the core skills they'll need throughout their academic journey. Outside of working with students, Olivia loves to be outdoors, and is frequently found skiing, biking, or trail running around Marin, San Francisco, and Tahoe.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Home;